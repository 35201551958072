import { connect } from 'react-redux';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import {
    selectedVariations
} from 'actions/admin/order';
import { useCallback, useEffect, useState } from 'react';

const SetProductQuantity = ({
    variationData,
    selectedVariations,
    customShaftChecked
}) => {

    const setQuantitys = useCallback((countType) => {
        const quantity = (countType === 'asc') ? (variationData.quantity + 1) : (variationData.quantity - 1)
        if (quantity > 0) {
            variationData = {
                ...variationData,
                quantity: quantity
            }
            if (variationData.stockQuantity >= quantity) {
                variationData = {
                    ...variationData,
                    variationSelectErrorMsg: ""
                }
            }
            if ((variationData.stockQuantity > 0) && (quantity > variationData.stockQuantity)) {
                variationData = {
                    ...variationData,
                    variationSelectErrorMsg: `The selected variation has a quantity of ${variationData.stockQuantity} available, but you have chosen a quantity of ${variationData.quantity}`
                }
            }
            selectedVariations(variationData)
        }
    })

    const handleChange = useCallback((e) => {
        const qty = Number(e.target.value) === 0 ? 1 : Number((e.target.value).toString().replace(/^0+/, ''));
        const regex = /^\d*$/;
        if (regex.test(qty) || qty === '') {
            if (variationData?.stock_quantity >= qty) {
                variationData = {
                    ...variationData,
                    quantity: Number(qty),
                }
                /* selectedVariations(variationData) */
            } else {
                if (variationData?.stock_quantity) {
                    variationData = {
                        ...variationData,
                        quantity: Number(variationData?.stock_quantity)
                    }
                } else {
                    variationData = {
                        ...variationData,
                        quantity: Number(qty)
                    }
                }
                /*  selectedVariations(variationData) */
            }
            if (variationData.stockQuantity >= variationData.quantity) {
                variationData = {
                    ...variationData,
                    variationSelectErrorMsg: ""
                }
            }
            if ((variationData.stockQuantity > 0) && (variationData.quantity > variationData.stockQuantity)) {
                variationData = {
                    ...variationData,
                    variationSelectErrorMsg: `The selected variation has a quantity of ${variationData.stockQuantity} available, but you have chosen a quantity of ${variationData.quantity}`
                }
            }
            selectedVariations(variationData)
        }
    }, [variationData]);

    return (
        <div className="quantity-tab">
            <b>Quantity : </b>
            {customShaftChecked && customShaftChecked == true ? (<div className="quantitytab">
                <button
                    type='button'
                    disabled={variationData.quantity === 1}
                    className="btn"
                    onClick={() => setQuantitys("desc")}
                >
                    <AiOutlineMinus />
                </button>
                <input
                    type="text"
                    name="quantity"
                    min={1}
                    className="form-control"
                    value={variationData.quantity}
                    onChange={handleChange}
                // readOnly
                />

                <button
                    className="btn"
                    type='button'
                    disabled={variationData.stock_quantity <= variationData.quantity}
                    onClick={() => setQuantitys("asc")}
                >
                    <AiOutlinePlus />
                </button>
            </div>) : (
                <>
                    <span>{1}</span>
                </>
            )}
        </div>
    )
}
const mapStateToProps = (state) => ({
    variationData: state.order.variationData,
});

export default connect(mapStateToProps, {
    selectedVariations
})(SetProductQuantity);