/* eslint-disable */
import React, { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';
import { convertCarrierTypes, convertServiceTypes } from 'actions/commonActions';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

const ShippingRates = ({
  shippingRatesLoading,
  // shippingRatesList,
  setSelectedShippingMode,
  selectedShippingMode,
  shippingModeError,
  shippingMessages,
  shippingRates,
  groupedRatesByProvider,
  setShippingPrice,
  selectedShippingPrice,
  reApplyCoupon,
  isApplied,
  callApplyCoupon,
  delivery_instruction,
  setDeliveryInstruction,
  shippingServiceCategory,
  variationData
}) => {
  //################ selected shipping method index ############################
  const [shippingMethod, setShippingMethod] = useState(0);

  //################ on change shipping method ############################
  const onShippingMethodChange = (e) => {
    setShippingMethod(e.target.value);
    if (shippingRates.length > 0) {
      setSelectedShippingMode(
        groupedRatesByProvider[e.target.value.split('-')[0]][
        e.target.value.split('-')[1]
        ]
      );
      let shippingPrice = groupedRatesByProvider[e.target.value.split('-')[0]][
        e.target.value.split('-')[1]
      ].totalCharges
        ? groupedRatesByProvider[e.target.value.split('-')[0]][
          e.target.value.split('-')[1]
        ].totalCharges
        : 0;
      if (shippingServiceCategory !== 2) {
        setShippingPrice(shippingPrice);
      }
      if (isApplied) {
        const isReApply = false
        reApplyCoupon(shippingPrice, isReApply);
      }
    }
  };

  // to resolve useEffect dependency error

  useEffect(() => {
    if (shippingRates.length === 0) {
      if (variationData.order_category !== "service_order") {
        setSelectedShippingMode({});
      }
      setShippingPrice(0);
      setShippingMethod(0);
    } else {
      let selectedIndex = shippingRates.findIndex((shipItem) => shipItem.carrier == selectedShippingMode.carrier && shipItem.service == selectedShippingMode.service);
      if (selectedIndex < 0) {
        const lowestRate = shippingRates.reduce((lowest, current) => {
          const currentCharges = parseFloat(current.totalCharges);
          const lowestCharges = lowest ? parseFloat(lowest.totalCharges) : Infinity;
          return currentCharges < lowestCharges ? current : lowest;
        }, null);
        if (lowestRate) {
          selectedIndex = shippingRates.findIndex((shipItem) => shipItem.carrier == lowestRate.carrier && shipItem.service == lowestRate.service);
        } else {
          selectedIndex = 0;
        }
      }
      setShippingMethod(shippingRates[selectedIndex].carrier + '-' + selectedIndex);
      setSelectedShippingMode(shippingRates[selectedIndex]);
      let shippingPrice = shippingRates[selectedIndex].totalCharges
        ? shippingRates[selectedIndex].totalCharges
        : 0;
      if (shippingServiceCategory !== 2) {
        setShippingPrice(shippingPrice);
      }
      if (isApplied) {
        if (callApplyCoupon === false) {
          const isReApply = true
          reApplyCoupon(shippingPrice, isReApply);
        }
      }
    }
  }, [shippingRates, callApplyCoupon]);

  return (
    <div className="details-view-outer">
      <Errors current_key="shippingRate" key="shippingRate" />
      {shippingRatesLoading ? (
        <Spinner />
      ) : (
        <Row>
          <Col>
            {shippingRates.length > 0 ? (
              Object.entries(groupedRatesByProvider)?.map(
                ([carrier, rates]) => {
                  return (
                    <div className="shipping-flex" key={carrier}>
                      <Label>
                        <b>{convertCarrierTypes(carrier)}</b>
                      </Label>
                      {rates?.map((value, key) => (
                        <FormGroup key={carrier + '-' + key}>
                          <div className="radio d-flex">
                            <Input
                              type="radio"
                              name="shippingMethod"
                              value={carrier + '-' + key}
                              checked={value?.service == selectedShippingMode?.service && value?.carrier == selectedShippingMode?.carrier}
                              // disabled
                              onChange={(e) => onShippingMethodChange(e)}
                              className="mr-2"
                            />
                            {shippingServiceCategory !== 2 ?
                              <Label>
                                {' '}
                                {convertServiceTypes(value.service)} : ${value.totalCharges}
                              </Label> : <Label>
                                {' '}
                                {convertServiceTypes(value.service)}
                              </Label>}
                          </div>
                        </FormGroup>
                      ))}
                    </div>
                  );
                }
              )
            ) : (
              <FormGroup>
                <div className="radio d-flex">
                  <Label> {shippingModeError}</Label>
                </div>
              </FormGroup>
            )}
            {shippingMessages.length > 0
              ? shippingMessages.map((value, key) => (
                <FormGroup key={key}>
                  <div>
                    {shippingMessages[key]?.source !==
                      shippingMessages[key - 1]?.source && (
                        <Label>
                          <b>{convertCarrierTypes(value.source)}</b>
                        </Label>
                      )}
                  </div>
                  <div className="radio d-flex">
                    <Label> {value.text}</Label>
                  </div>
                </FormGroup>
              ))
              : null}
          </Col>
          <Col md="12">
            <FormGroup>
              <div className="couponcode">
                <Label for="deliveryInstruction">
                  <strong>Delivery Instructions</strong>
                </Label>
              </div>
              <Input
                type="textarea"
                className="form-control text-left"
                id="deliveryInstruction"
                name="deliveryInstruction"
                maxLength="100"
                // placeholder="Delivery Instruction(max chars limit is 100)"
                defaultValue={delivery_instruction}
                onChange={(e) => setDeliveryInstruction(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </div>
  );
};

ShippingRates.propTypes = {
  // loading: PropTypes.bool.isRequired,
  shippingRatesLoading: PropTypes.bool.isRequired,
  // shippingRatesList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  // loading: state.order.shipping_rates_loading,
  shippingRatesLoading: state.order.shipping_rates_loading,
  variationData: state.order.variationData,
  // shippingRatesList: state.order.shippingRatesList,
});

export default connect(mapStateToProps)(memo(ShippingRates));
