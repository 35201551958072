/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import store from '../../../store';
import { connect } from 'react-redux';
import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';
import {
  Input,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Form,
  FormText,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import {
  ORDER_LIST_ORDER_STATUS,
  USER_TYPE,
  ORDER_LIST_BUILD_SHOP_MANAGEMENT_ORDER_STATUS,
  ORDER_LIST_ORDER_MANAGEMENT_ORDER_STATUS,
  ORDER_LIST_SHIPPING_MANAGEMENT_ORDER_STATUS,
  ORDERS_PAYMENT_STATUS
} from 'constants/index';

import {
  getOrdersList,
  changeStatus,
  changeOrderStatus,
  getOrdersToDownload,
  getOrdersListStaff,
  downloadInvoice,
  sendEmailToServer,
  changePaymentStatus,
} from 'actions/admin/order';

import * as Constants from 'constants/index';
import Moment from 'react-moment';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import PerPageSelect from '../PerPageSelect';
import { loadUser } from 'actions/auth';
import RefundModel from './Models/RefundModel';
import PaymentTypeModel from './Models/PaymentTypeModel';
import DeliveredRemarkModel from './Models/DeliveredRemarkModel';
import ButtonSpinner from 'views/ButtonSpinner';

const renderOrderId = (params) => {
  if (params.data === undefined) {
    // when no node id, display the spinner image
    return (
      <center>
        <span>Loading.......</span>
      </center>
    );
  } else {
    // otherwise just display node ID (or whatever you wish for this column)
    return <div>{params.data?.order_id}</div>;
  }
};

const PaymentStatus = (params) => {
  return ORDERS_PAYMENT_STATUS.map((paymentStatus) => {
    return (
      <option
        value={paymentStatus.key}
        key={paymentStatus.key}
      >
        {paymentStatus.value}
      </option>
    );
  })
};

const OrderStatus = (params) => {
  return ORDER_LIST_ORDER_STATUS.map((orderStatus) => {
    let isOrderStatusDisabled = false;
    let orderCatType = (params.data.order_category === "service_order" && params.data.shippingServiceCategory === 1) ? true : false;
    switch (params?.data?.order_status) {
      case 1:
        isOrderStatusDisabled = ![2, 4].includes(orderStatus.key);
        break;
      case 2:
        isOrderStatusDisabled = ![1, 4, 5, 6, 7].includes(orderStatus.key);
        break;
      case 3:
      case 12:
        isOrderStatusDisabled = ![5, 8].includes(orderStatus.key);
        break;
      case 5:
        isOrderStatusDisabled = orderCatType ? ![2, 3, 4, 6, 7].includes(orderStatus.key) : ![2, 4, 6, 7].includes(orderStatus.key);
        break;
      case 6:
        isOrderStatusDisabled = orderCatType ? ![2, 3, 4, 5, 7].includes(orderStatus.key) : ![2, 4, 5, 7].includes(orderStatus.key);
        break;
      case 7:
        isOrderStatusDisabled = orderCatType ? false : ![2, 4, 5, 6].includes(orderStatus.key);
        break;
      case 8:
        //isOrderStatusDisabled = ![9].includes(orderStatus.key);
        isOrderStatusDisabled = ![8].includes(orderStatus.key);
        break;
      case 9:
        isOrderStatusDisabled = orderCatType ? false : ![10, 11].includes(orderStatus.key);
        break;
      case 10:
        isOrderStatusDisabled = orderCatType ? false : ![11].includes(orderStatus.key);
        break;
      case 4:
      case 11:
        isOrderStatusDisabled = orderCatType ? true : ![0].includes(orderStatus.key);
        break;
      default:
    }
    return (
      <option
        value={orderStatus.key}
        key={orderStatus.key}
        disabled={isOrderStatusDisabled}
      >
        {orderStatus.value}
      </option>
    );
  });
};

const OrderActions = (params, toggleViewRefundModal, downloadInvoice) => {
  const handleRefund = (order_id, orderStatus, orderTotalAmountVal, order) => {
    params.setIsRefundModal(true);
    params.setOldOrderStatus(orderStatus);
    params.setOrderTotalAmount(orderTotalAmountVal);
    params.setRefundFormData({
      ...params.refundFormData,
      order_id: order_id,
      order: order,
      previous_order_status: orderStatus,
      new_order_status: 11,
      grand_total: orderTotalAmountVal.$numberDecimal,
      product: order.product,
    });
  };

  const handleDownloadInvoice = (order_id, index) => {
    params.setIndexId(index);
    params.setFieldName("download_invoice")
    params.setLoadingAction(true);
    params.setDownloadInvoiceStatus(order_id);
  };

  const handleEmail = (order_id, index) => {
    params.setFieldName("send_email")
    params.setIndexId(index);
    params.setLoadingAction(true);
    params.setSendEmail(order_id);
  };

  let mailIconDisabled = false;
  let viewIconDisabled = false;
  let logsIconDisabled = false;
  let paymentIconDisabled = false;
  let editIconDisabled = false;
  let shipLabelDisabled = false;
  let trackDisabled = false;
  let refundDisabled = false;
  let downloadInvoiceDisabled = false;
  let orderCatType = (params.data.order_category === "service_order" && params.data.shippingServiceCategory === 1) ? true : false;
  let hideRefundOptions = (params.data.order_category === "service_order" && params.data.shippingServiceCategory === 1) ? true : ([1, 2].includes(params.data.payment_status) ? false : true);
  switch (params.data.order_status) {
    case 1:
      editIconDisabled = false;
      shipLabelDisabled = orderCatType ? true : false;
      trackDisabled = true;
      refundDisabled = true;
      downloadInvoiceDisabled = true;
      mailIconDisabled = false;
      break;
    case 11:
      trackDisabled = true;
      editIconDisabled = true;
      refundDisabled = false;
      shipLabelDisabled = orderCatType ? true : false;
      mailIconDisabled = true;
      break;
    case 2:
      mailIconDisabled = (params.data.payment_status !== 2) ? false : true;
      trackDisabled = true;
      refundDisabled = true;
      shipLabelDisabled = orderCatType ? true : false;
      downloadInvoiceDisabled = false;
      editIconDisabled = false;
      break;
    case 3:
    case 12:
      mailIconDisabled = true;
      editIconDisabled = true;
      refundDisabled = (params.data?.order_shipping_boxes?.shipping_box_status?.includes(3) && params.data.order_status === 12) ? false : true;
      shipLabelDisabled = orderCatType ? true : false;
      break;
    case 4:
      mailIconDisabled = true;
      editIconDisabled = true;
      shipLabelDisabled = orderCatType ? true : false;
      trackDisabled = true;
      refundDisabled = true;
      downloadInvoiceDisabled = true;
      break;
    case 5:
      mailIconDisabled = true;
      editIconDisabled = true;
      trackDisabled = true;
      refundDisabled = true;
      shipLabelDisabled = orderCatType ? true : false;
      break;
    case 6:
    case 11:
      mailIconDisabled = true;
      editIconDisabled = true;
      trackDisabled = true;
      refundDisabled = false;
      shipLabelDisabled = orderCatType ? true : false;
      break;
    case 8:
      mailIconDisabled = true;
      editIconDisabled = true;
      trackDisabled = true;
      refundDisabled = hideRefundOptions ? true : false;
      shipLabelDisabled = orderCatType ? true : false;
      break;
    case 7:
      mailIconDisabled = true;
      trackDisabled = true;
      refundDisabled = true;
      shipLabelDisabled = orderCatType ? true : false;
      break;

    case 9:
      mailIconDisabled = true;
      trackDisabled = true;
      refundDisabled = false;
      editIconDisabled = true;
      break;
    case 10:
      mailIconDisabled = true;
      editIconDisabled = true;
      trackDisabled = true;
      refundDisabled = false;
      shipLabelDisabled = orderCatType ? true : false;
      break;

    default:
  }
  return (
    <>
      <a
        onClick={() => handleDownloadInvoice(params.data._id, params.rowIndex)}
        className={(downloadInvoiceDisabled == true || (params.indexId === params.rowIndex && params.loadingAction === true)) ? 'disabled-link' : ''}
      >
        {(params.indexId === params.rowIndex && params.loadingAction === true && params.fieldName === "download_invoice") ? <div color="info" className="action loading btn btn-info  "><ButtonSpinner /></div> :
          <Button disabled={downloadInvoiceDisabled} type="button" size="sm" color="info" title="Download Invoice">
            {/* <i className="fa fa-file-text" id="invoice"></i> */}
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 309 402"
            >
              <path d="m29.87,395.75c-2.97-1.23-6.16-2.11-8.87-3.76-7.21-4.41-10.86-11.09-11.32-19.52-.04-.75-.02-1.5-.02-2.24,0-110.75,0-221.51,0-332.26,0-13.18,7.69-22.82,20.02-25.09,1.7-.31,3.47-.36,5.21-.36,50.89-.02,101.77,0,152.66-.05,4.53,0,8.15,1.42,11.36,4.64,31.09,31.17,62.22,62.31,93.4,93.39,3.42,3.41,4.84,7.23,4.83,12.03-.07,81.44-.05,162.89-.05,244.33,0,16.92-4.4,23.2-20.21,28.9H29.87ZM177.33,36.72H33.92v334.86h238.97v-239.32c-1.61,0-2.96,0-4.32,0-22.2,0-44.41.05-66.61-.02-14.22-.04-24.54-10.37-24.61-24.57-.06-14.1-.01-28.19-.02-42.29,0-9.47,0-18.94,0-28.67Zm24.21,17.84v53.47h53.47c-17.82-17.82-35.65-35.65-53.47-53.47Z" />
              <path d="m93.5,85.66c0-4.44-.06-8.68.01-12.91.11-7.05,5.2-12.29,11.88-12.33,6.66-.04,11.9,5.2,12.04,12.18.09,4.35.02,8.7.02,12.07,6.67,3.41,13,6.08,18.71,9.73,6.5,4.14,7.76,11.57,3.77,17.2-4.12,5.8-11.34,6.63-17.82,2.05-9.67-6.83-22.8-7.14-32.36-.77-10.98,7.31-10.91,19.63.17,26.88,5.3,3.47,11.23,4.34,17.46,4.59,15.2.62,28.18,6.26,37.74,18.38,14.52,18.41,9.49,43.94-11.28,56.9-4.91,3.06-10.49,5.04-16.37,7.8,0,3.47.04,7.67,0,11.88-.08,7.33-5.11,12.67-11.9,12.7-6.79.04-11.94-5.32-12.03-12.58-.05-4.11,0-8.22,0-12.58-1.06-.37-2.13-.62-3.08-1.09-5.45-2.68-11.16-4.95-16.22-8.23-6.01-3.89-7.04-11.27-3.15-16.72,3.99-5.59,11.23-6.63,17.2-2.48,10.66,7.41,23.83,7.72,33.58.78,10.01-7.12,10.1-18.65.13-25.83-4.91-3.54-10.5-5.04-16.52-5.1-9.67-.09-18.84-2.28-27.05-7.44-13.78-8.66-22.1-20.83-20.58-37.56,1.6-17.58,12.56-28.42,28.25-35.02,2.28-.96,4.71-1.6,7.44-2.5Z" />
              <path d="m153.21,275.98c27.69,0,55.38-.02,83.08.01,9.19.01,15.22,8.02,12.1,16.09-1.89,4.88-5.61,7.56-10.85,7.8-3.61.17-7.23.04-10.85.04-51.89,0-103.78-.02-155.67.03-5.49,0-9.79-1.86-12.25-6.9-3.82-7.84,1.76-16.84,10.63-17.03,8.35-.18,16.71-.04,25.07-.04,19.58,0,39.17,0,58.75,0Z" />
              <path d="m153.75,323.89c27.57,0,55.13-.01,82.7.01,6.21,0,10.74,3.37,12.31,8.93,1.4,4.97-.69,10.74-5.42,13.14-2.43,1.23-5.46,1.79-8.22,1.79-51.14.1-102.28.07-153.43.07-3.37,0-6.74,0-10.1,0-8.6-.01-14.07-4.74-14.01-12.08.06-7.18,5.51-11.86,13.85-11.86,27.44,0,54.88,0,82.33,0Z" />
              <path d="m213.08,204.12c-7.61,0-15.22.03-22.82,0-7.61-.04-12.96-5.07-12.92-12.04.04-6.94,5.42-11.88,13.06-11.89,15.21-.03,30.43-.03,45.64,0,7.65.01,13.06,4.94,13.12,11.85.06,7.06-5.4,12.06-13.27,12.09-7.61.03-15.21,0-22.82,0Z" />
              <path d="m213.25,228.08c7.73,0,15.46-.04,23.2.01,7.32.05,12.67,5.09,12.72,11.87.05,6.8-5.29,12.02-12.54,12.05-15.59.06-31.18.06-46.77,0-7.29-.03-12.57-5.22-12.52-12.06.05-6.8,5.38-11.81,12.72-11.86,7.73-.05,15.46-.01,23.2-.01Z" />
              {' '}
            </svg>
          </Button>}
      </a>
      <Link
        to={`/admin/orders/details/${params.data?._id}`}
        className={viewIconDisabled == true ? 'disabled-link' : ''}
      >
        <Button disabled={viewIconDisabled} type="button" size="sm" color="info" title="View Order">
          <i className="fa fa-eye"></i>
        </Button>
      </Link>
      <Link
        to={`/admin/${params.data?.order_category === 'service_order' ? 'service' : 'order'}/edit/${params.data?._id}`}
        className={editIconDisabled == true ? 'disabled-link' : ''}
      >
        <Button disabled={editIconDisabled} type="button" size="sm" color="success" title="Edit Order">
          <i className="fa fa-pencil"></i>
        </Button>
      </Link>

      <Link
        to={`/admin/orders/shipping-boxes/${params.data?.orderMergeShippingId ? params.data?.orderMergeShippingId : params.data?._id}`}
        className={shipLabelDisabled == true ? 'disabled-link' : ''}
      >
        <Button
          disabled={shipLabelDisabled}
          type="button"
          size="sm"
          color="success"
          title="Shipping Options"
        >
          <i className="fa fa-print" id="printLabel"></i>
        </Button>
      </Link>

      <Link
        to={`/admin/orders/order-details/payments/${params.data?._id}`}
        className={paymentIconDisabled == true ? 'disabled-link' : ''}
      >
        <Button disabled={paymentIconDisabled} type="button" size="sm" color="info" title="View Order Payments">
          <i className="fa fa-dollar" aria-hidden="true"></i>
        </Button>
      </Link>
      <Link
        className={(refundDisabled === true || params.data?.orderMergeShippingId) ? 'disabled-link' : ''}
        to={`/admin/return-initiate/${params.data?._id}`}
      >
        <Button disabled={(refundDisabled === true || params.data?.orderMergeShippingId) ? true : false} type="button" size="sm" color="info" title={`Initiate Return`}>
          <i className="fa fa-undo" id="refund"></i>
        </Button>
      </Link>

      <Link
        to={`/admin/orders/order-details/logs/${params.data?._id}`}
        className={logsIconDisabled == true ? 'disabled-link' : ''}
      >
        <Button disabled={logsIconDisabled} type="button" size="sm" color="info" title="View Order Logs">
          <i className="fa fa-book fa-fw" aria-hidden="true"></i>
        </Button>
      </Link>

      <a
        onClick={() => handleEmail(params.data._id, params.rowIndex)}
        className={(mailIconDisabled == true || (params.indexId === params.rowIndex && params.loadingAction === true)) ? 'disabled-link' : ''}
      >
        {(params.indexId === params.rowIndex && params.loadingAction === true && params.fieldName === "send_email") ? <div color="info" className="action loading btn btn-info  "><ButtonSpinner /></div> :
          <Button
            disabled={mailIconDisabled}
            type="button"
            size="sm"
            color="info"
            title="Resend Payment Link"
          >
            <i className="fa fa-envelope"></i>
          </Button>}
      </a >
    </>
  );
};

const TWO_MINUTE_MS = 120000;
const OrdersList = ({
  getOrdersList,
  changeStatus,
  changeOrderStatus,
  orderList: { data, count },
  auth: { user },
  getOrdersListStaff,
  downloadInvoice,
  sendEmailToServer,
  changePaymentStatus,
}) => {
  const [isRefundModal, setIsRefundModal] = useState(false);
  const [isDeliveryRemarkModal, setIsDeliveryRemarkModal] = useState(false);
  const [isPreviewModel, setPreviewModel] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [validateOnHold, setValidateOnHold] = useState(false);
  const [onHoldNotes, setOnHoldNotes] = useState('');
  const [oldOrderStatus, setOldOrderStatus] = useState(0);
  const [orderTotalAmount, setOrderTotalAmount] = useState(0);
  const [refundInitiated, setRefundInitiated] = useState(false);
  const [downloadInvoiceStatus, setDownloadInvoiceStatus] = useState('');
  const [sendEmail, setSendEmail] = useState('');
  const [downloadInvoiceURL, setDownloadInvoiceURL] = useState('');
  const [orderStatus, setOrderStatus] = useState([]);
  const [activeClass, setActiveClass] = useState('');
  const [validate, setValidate] = useState(false);
  const [validateMoreAmount, setValidateMoreAmount] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [loadingAction, setLoadingAction] = useState(false);
  const [indexId, setIndexId] = useState(0);
  const [fieldName, setFieldName] = useState("");

  const [refundFormData, setRefundFormData] = useState({
    refunded_amount: 0,
    previous_order_status: '',
    new_order_status: 11,
    refund_notes: '',
    product: [],
  });
  const [deliveryRemark, setDeliveryRemark] = useState({
    order_id: "",
    previous_order_status: "",
    new_order_status: "",
    remark: ""
  })
  const initialState = {
    refunded_amount: 0,
    previous_order_status: '',
    new_order_status: 11,
    refund_notes: '',
  };

  const togglePaymentTypeModel = (isReloadData = false) => {
    setPaymentModel(!isPaymentModel);
    if (isReloadData === true) {
      gridApi.purgeInfiniteCache();
    }
  };

  const toggleDeliveredModal = (isReloadData = false) => {
    setIsDeliveryRemarkModal(!isDeliveryRemarkModal)
    if (isReloadData === true) {
      gridApi.purgeInfiniteCache();
    }
  };

  const toggleRefundModal = (isReloadData = false) => {
    setIsRefundModal(!isRefundModal);
    if (isReloadData === true) {
      gridApi.purgeInfiniteCache();
    }
  };
  const toggleViewRefundModal = (e) => {
    setIsRefundModal(!isRefundModal);
    if (e?.currentTarget?.getAttribute('data-id') == 'closeRefundModel') {
      setTimeout(() => {
        setPreviewModel(false);
        setIsRefundModal(false);
      }, 200);
      setRefundFormData(initialState);
      setValidate(false);
      setValidateMoreAmount(false);
    }
  };

  const togglePreviewModel = (e) => {
    if (e?.currentTarget?.getAttribute('data-id') == 'closeModel') {
      setTimeout(() => {
        setPreviewModel(false);
        setIsRefundModal(false);
      }, 200);
      setRefundFormData(initialState);
      setValidate(false);
      setValidateMoreAmount(false);
    }
    if (
      refundFormData.refunded_amount == undefined ||
      refundFormData.refunded_amount == ''
    ) {
      setValidate(true);
      setValidateMoreAmount(false);
      return false;
    }
    if (
      Number(refundFormData.refunded_amount) >
      Number(refundFormData.grand_total)
    ) {
      setValidateMoreAmount(true);
      setValidate(false);
      return false;
    } else {
      setValidate(false);
      setValidateMoreAmount(false);
      setIsRefundModal(!isRefundModal);
      setPreviewModel(!isPreviewModel);
    }
  };
  const [refundInitiatedFormData, setRefundInitiatedFormData] = useState({
    refunded_amount: 0,
    previous_order_status: '',
    new_order_status: 11,
  });

  useEffect(() => {
    if (data.length > 0) {
      store.dispatch(loadUser({ loading: false }));
    }
  }, [data]);


  useEffect(() => {
    const interval = setInterval(() => {
      store.dispatch(loadUser({ loading: false }));
      setRefreshCount(prevCount => prevCount + 1);
    }, TWO_MINUTE_MS);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => {
    if (user.order_count) {
      let statusCount = [];
      let statusLabel = {
        ['PENDING PAYMENT']: 'order_pending',
        ['PAID']: 'order_paid',
        ['RETURN INITIATED']: 'order_return_initiated',
        ['RETURNED']: 'order_returned',
        ['CANCELLED']: 'order_cancelled',
        /* ['PAYMENT_PAID']: 'payment_paid' */
      };
      const index = user.order_count.findIndex(item => item._id === 4);
      if (index !== -1) {
        // Remove the object and push it to the end
        const [item] = user.order_count.splice(index, 1);
        user.order_count.push(item);
      }
      for (const item of user.order_count) {
        const status = ORDER_LIST_ORDER_MANAGEMENT_ORDER_STATUS?.concat([{ value: "PAYMENT_PAID", key: 22 }]).find(
          (items) => items.key === item._id
        );
        if (item._id !== 22) {
          statusCount.push({
            statusText: status?.value === "PAID" ? "NEW" : status?.value,
            statusCount: item?.count,
            status_label: statusLabel[status.value],
          });
        }
      }
      setOrderStatus(statusCount);
    }
  }, [user]);

  const handleRefundChange = (e) => {
    setRefundFormData({ ...refundFormData, [e.target.name]: e.target.value });
  };

  const handleChangeOnHold = (e) => {
    setOnHoldNotes(e.target.value);
    if (onHoldNotes != '') {
      setValidateOnHold(false);
    }
  };

  const handleOnHoldSubmit = async (e) => {
    let statusFromData = {
      order_id: refundFormData?.order_id,
      previous_order_status: refundFormData?.previous_order_status,
      new_order_status: 7,
      refunded_amount: 0,
      refund_notes: onHoldNotes,
    }
    if (onHoldNotes == '') {
      setValidateOnHold(true);
    } else {
      const orderResponse = await changeOrderStatus(statusFromData, history);
      if (orderResponse?.data?.status) {
        setValidateOnHold(false);
        setIsOnHold(!isOnHold);
        setOnHoldNotes('');
        gridApi.purgeInfiniteCache();
      }
    }
  }

  useEffect(async () => {
    if (downloadInvoiceStatus) {
      let invoiceRespose = await downloadInvoice(downloadInvoiceStatus);
      if (invoiceRespose) {
        setFieldName("")
        setLoadingAction(false);
        setDownloadInvoiceStatus('');
        setDownloadInvoiceURL(invoiceRespose.data);
        document.getElementById('downloadInvoice').click();
      }
    }
  }, [downloadInvoiceStatus]);

  useEffect(async () => {
    if (sendEmail) {
      let emailResponse = await sendEmailToServer(sendEmail);
      if (emailResponse) {
        setFieldName("")
        setLoadingAction(false)
        setSendEmail('');
      }
    }
  }, [sendEmail]);

  /**************** on submit event *********************/
  const onAddRefund = (e) => {
    e.preventDefault();
    changeOrderStatus(refundFormData).then((res) => {
      if (res.data.status == true) {
        setPreviewModel(false);
        setIsRefundModal(false);
        gridApi.purgeInfiniteCache();
      }
    });
  };

  const toggleOnHoldModal = (isReloadData = false) => {
    setIsOnHold(!isOnHold);
    if (isReloadData === true) {
      gridApi.purgeInfiniteCache();
    }
  };

  useEffect(async () => {
    if (refundInitiated) {
      changeOrderStatus(refundInitiatedFormDatas).then((res) => {
        if (res.data.status == true) {
          toggleViewRefundModal();
          getOrdersList();
        }
      });
    }
  }, [refundInitiated]);

  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'ordered_on',
        ascending: 'desc',
        query: '',
        filter: {},
        onLoad: true,
      }
  );

  //csv download
  const [downloadData, setDownloadData] = useState([]);

  // to resolve useEffect dependency error

  //################## to get updated list ###################

  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [paymentStatusDetail, setPaymentStatusDetail] = useState(null);
  const [isPaymentModel, setPaymentModel] = useState(false);

  const updatePaymentStatus = useCallback((
    order,
    value,
    previous_order_status,
    payment_status,
    grand_total
  ) => {
    setPaymentStatusDetail({ order_id: order._id, current_payment_status: value, previous_order_status, payment_status })
    if (Number(value) === 2) {
      setPaymentModel(true)
    } else {
      changePaymentStatus({ order_id: order._id, current_payment_status: value, previous_order_status, payment_status }).then((res) => {
        if (res.status === true) {
          gridApi.purgeInfiniteCache();
        }
      });
    }
    //const [isRefundModal, setIsRefundModal] = useState(false);
  });

  const changeOrderDetails = async (
    order,
    value,
    previous_order_status,
    payment_status,
    grand_total,
  ) => {
    let statusFromData = {
      order_id: order?._id,
      previous_order_status: previous_order_status,
      new_order_status: value,
      refunded_amount:
        [2].includes(Number(payment_status)) && value == 4 ? Number(grand_total.$numberDecimal) : 0,
    };

    if ([5, 6, 7].includes(Number(order?.order_status)) && Number(order?.payment_status) === 2 && value == 4) {

      setOrderTotalAmount(grand_total.$numberDecimal);
      setRefundFormData({
        ...refundFormData,
        refunded_amount: '',
        previous_order_status: previous_order_status,
        order_id: order?._id,
        order: order,
        new_order_status: Number(value),
        product: order.product,
        grand_total: grand_total.$numberDecimal,
      });
      toggleRefundModal();
      gridApi.purgeInfiniteCache();
      return false;
    } else if (value == 7) {
      setRefundFormData({
        ...refundFormData,
        previous_order_status: previous_order_status,
        order_id: order?._id,
        grand_total: grand_total.$numberDecimal,
      });
      setValidateOnHold(false);
      toggleOnHoldModal();
    } else if (value == 8) {
      if (order?.order_category === "service_order" && order?.shippingServiceCategory === 1) {
        setDeliveryRemark(statusFromData)
        setIsDeliveryRemarkModal(!isDeliveryRemarkModal)
      } else {
        await changeOrderStatus(statusFromData, history);
        gridApi.purgeInfiniteCache();
      }
    } else {
      setRefundFormData({
        ...refundFormData,
        previous_order_status: previous_order_status,
        order_id: order?._id,
        grand_total: grand_total.$numberDecimal,
      });
      await changeOrderStatus(statusFromData, history);
      gridApi.purgeInfiniteCache();
    }
  };

  const anyFilter = {
    displayName: 'ANY',
    displayKey: 'any',
    numberOfInputs: 0,
    predicate: () => { },
  };

  //#########################Colums defination start ###################
  let columns = [];
  if (
    user?.isAdmin ||
    (user?.isStaff &&
      user?.permission.includes(Constants.PERMISSION_NAME.ORDER_MANAGEMENT))
  ) {
    columns = [
      {
        field: 'order_id',
        headerName: 'Order Id',
        width: 120,
        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          closeOnApply: true,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
          debounceMs: 800,
        },
        colSpan: (params) => {
          if (params.data === undefined) {
            return 10;
          } else {
            return 0;
          }
        },

        cellRenderer: renderOrderId,
      },
      {
        headerName: 'Type',
        field: 'order_type',
        width: 90,
        filter: 'agNumberColumnFilter',
        sortable: true,
        filterParams: {
          closeOnApply: true,
          filterOptions: [
            anyFilter,
            {
              displayName: 'Online',
              displayKey: 'typeWeb',
              numberOfInputs: 0,
              predicate: () => { },
            },
            {
              displayName: 'Manual',
              displayKey: 'typeAdmin',
              numberOfInputs: 0,
              predicate: () => { },
            },
          ],
          suppressAndOrCondition: true,
        },
      },
      {
        field: 'ordered_on',
        headerName: 'Date',
        width: 130,
        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        sortable: true,
        filter: 'agDateColumnFilter',
        // pass in additional parameters to the text filter
        filterParams: {
          closeOnApply: true,
          suppressAndOrCondition: true,
          filterOptions: Constants.DATE_FILTER_OPTIONS,
          debounceMs: 800,
          buttons: ['reset', 'apply'],
        },
        cellRenderer: (params) => (
          <>
            <div className="primary-Outer">
              <Moment
                format={
                  process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'
                }
                locale="de"
              >
                {params.value}
              </Moment>
              <span>
                <Moment format="HH:mm" locale="de">{params.value}</Moment>
              </span>
            </div>
          </>
        ),
      },
      {
        field: 'user_type',
        headerName: 'Customer Type',
        width: 140,
        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        filter: 'agTextColumnFilter',
        filterParams: {
          closeOnApply: true,
          filterOptions: [
            anyFilter,
            {
              displayName: 'Customer',
              displayKey: 'typeCustomer',
              numberOfInputs: 0,
              predicate: () => { },
            },
            {
              displayName: 'Dealer',
              displayKey: 'typeDealer',
              numberOfInputs: 0,
              predicate: () => { },
            },
          ],
          suppressAndOrCondition: true,
        },
        cellRenderer: ({ data }) => {
          if (data?.user_type === 2) {
            return <span>Customer</span>;
          } else if (data?.user_type === 3) {
            return <span>Dealer</span>;
          } else if (data?.user_type === 5) {
            return <span>Staff</span>;
          } else if (data?.user_type === 1) {
            return <span>Admin</span>;
          } else {
            return <span>''</span>;
          }
        },
      },
      {
        field: 'user_email',
        headerName: 'Customer',
        width: 160,
        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        filter: 'agTextColumnFilter',
        filterParams: {
          closeOnApply: true,
          debounceMs: 800,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
        },
        cellRenderer: ({ data }) => {
          const userName = () => (
            <>
              <div className="primary-Outer">
                {data?.dealer_business ?? data?.user_name}
                <span>{data?.user_email}</span>
              </div>
            </>
          );
          const dealer =
            parseInt(data?.user_type) === USER_TYPE['DEALER']
              ? 'dealers'
              : undefined;

          const url =
            parseInt(data?.user_type) === USER_TYPE['CUSTOMER']
              ? 'customers'
              : dealer;

          return (
            <>
              {url ? (
                <Link to={`/admin/${url}/edit/${data?.user_id}`}>
                  {userName()}
                </Link>
              ) : (
                userName()
              )}
            </>
          );
        },
      },
      {
        field: 'shipping_city',
        headerName: 'Ship To',
        width: 140,
        suppressSizeToFit: false,
        suppressMovable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          closeOnApply: true,
          debounceMs: 800,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
        },

        cellClass: 'suppress-movable-col',
        sortable: true,
        cellRenderer: (params) => (
          <div>
            <div className="primary-Outer">
              {params.data.shipping_city +
                ', ' +
                (params.data.shipping_state ? params.data.shipping_state : ' ')}
              <span>{params.data.shipping_country}</span>
            </div>
          </div>
        ),
      },

      {
        field: 'grand_total',
        headerName: 'Amount',
        width: 100,
        suppressSizeToFit: false,
        suppressMovable: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
        filterParams: {
          closeOnApply: true,
          debounceMs: 800,
          filterOptions: Constants.NUMBER_FILTER_OPTIONS,
          suppressAndOrCondition: true,
        },
        cellClass: 'suppress-movable-col',
        cellRenderer: (params) => (
          <div>${params.data?.grand_total['$numberDecimal']}</div>
        ),
      },
      {
        field: 'payment_status',
        headerName: 'Payment Status',
        width: 200,
        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        cellRenderer: (params) => {
          return (<>
            <Input
              type="select"
              name="payment_status"
              disabled={params.data?.payment_status === 2 ? true : false}
              id={params.data?._id}
              value={params.data?.payment_status}
              onChange={async (e, a) => {
                e.preventDefault();
                if (
                  window.confirm(
                    `Are you sure, you want to change payment status of ${params.data?.order_id}?`
                  )
                ) {
                  updatePaymentStatus(
                    params.data,
                    e.target.value,
                    params.data.order_status,
                    params.data.payment_status,
                    params.data.grand_total
                  );
                }
              }}
            >
              <PaymentStatus {...params} />
            </Input>
          </>)
        }
      },
      {
        field: 'order_status',
        headerName: 'Status',
        width: 200,
        suppressSizeToFit: false,
        suppressMovable: true,
        filter: 'agSetColumnFilter',
        // pass in additional parameters to the text filter
        filterParams: {
          closeOnApply: true,
          filterOptions: [
            anyFilter,
            {
              displayName: 'PENDING PAYMENT',
              displayKey: 'order_pending',
              numberOfInputs: 0,
              predicate: () => { },
              value: 1,
            },
            {
              displayName: 'NEW',
              displayKey: 'order_paid',
              numberOfInputs: 0,
              predicate: () => { },
              value: 2,
            },
            {
              displayName: 'PROCESSING',
              displayKey: 'order_processing',
              numberOfInputs: 0,
              predicate: () => { },
              value: 5,
            },
            {
              displayName: 'BUILD SHOP',
              displayKey: 'order_build_shop',
              numberOfInputs: 0,
              predicate: () => { },
              value: 6,
            },
            {
              displayName: 'SHIPPED',
              displayKey: 'order_shipped',
              numberOfInputs: 0,
              predicate: () => { },
              value: 3,
            },
            {
              displayName: 'ON HOLD',
              displayKey: 'order_on_hold',
              numberOfInputs: 0,
              predicate: () => { },
              value: 7,
            },
            {
              displayName: 'CANCELLED',
              displayKey: 'order_cancelled',
              numberOfInputs: 0,
              predicate: () => { },
              value: 4,
            },
            {
              displayName: 'RETURN INITIATED',
              displayKey: 'order_return_initiated',
              numberOfInputs: 0,
              predicate: () => { },
              value: 9,
            },
            {
              displayName: 'RETURNED',
              displayKey: 'order_returned',
              numberOfInputs: 0,
              predicate: () => { },
              value: 10,
            },
            {
              displayName: 'REFUNDED',
              displayKey: 'order_refunded',
              numberOfInputs: 0,
              predicate: () => { },
              value: 11,
            },
            {
              displayName: 'DELIVERED',
              displayKey: 'order_delivered',
              numberOfInputs: 0,
              predicate: () => { },
              value: 8,
            },
            {
              displayName: 'PARTIALLY SHIPPED',
              displayKey: 'partially_shipped',
              numberOfInputs: 0,
              predicate: () => { },
              value: 12,
            },
          ],
          suppressAndOrCondition: true,
        },
        cellClass: 'suppress-movable-col',

        sort: true,

        cellRenderer: (params) => {
          return (
            <div>
              <Input
                type="select"
                name="order_status"
                id={params.data?._id}
                value={params.data?.order_status}
                onChange={async (e, a) => {
                  e.preventDefault();
                  if (
                    window.confirm(
                      `Are you sure, you want to change order status of ${params.data?.order_id}?`
                    )
                  ) {
                    changeOrderDetails(
                      params.data,
                      e.target.value,
                      params.data.order_status,
                      params.data.payment_status,
                      params.data.grand_total
                    );
                  }
                }}
              >
                {user?.isAdmin && <OrderStatus {...params}></OrderStatus>}
                {user?.isStaff &&
                  user?.permission.includes(
                    Constants.PERMISSION_NAME.ORDER_MANAGEMENT
                  ) && <OrderStatus {...params}></OrderStatus>}
                {user?.isStaff &&
                  user?.permission.includes(
                    Constants.PERMISSION_NAME.BUILD_SHOP_MANAGEMENT
                  ) &&
                  ORDER_LIST_BUILD_SHOP_MANAGEMENT_ORDER_STATUS.map((item) =>
                    item && item.value == 'BUILD SHOP' ? (
                      <option
                        disabled="disabled"
                        value={item.key}
                        key={item.key}
                      >
                        {item.value}
                      </option>
                    ) : (
                      <option value={item.key} key={item.key}>
                        {item.value}
                      </option>
                    )
                  )}
                {user?.isStaff &&
                  user?.permission.includes(
                    Constants.PERMISSION_NAME.SHIPPING_MANAGEMENT
                  ) &&
                  ORDER_LIST_SHIPPING_MANAGEMENT_ORDER_STATUS.map((item) =>
                    item && item.value == 'PROCESSING' ? (
                      <option
                        disabled="disabled"
                        value={item.key}
                        key={item.key}
                      >
                        {item.value}
                      </option>
                    ) : (
                      <option value={item.key} key={item.key}>
                        {item.value}
                      </option>
                    )
                  )}
              </Input>
            </div>
          );
        },
      },
      {
        field: '_id',
        headerName: 'Actions',
        suppressSizeToFit: false,
        width: '400',
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        cellRenderer: (params) => (
          <div>
            {user?.isAdmin ||
              (user?.isStaff &&
                user?.permission.includes(
                  Constants.PERMISSION_NAME.ORDER_MANAGEMENT
                )) ? (
              <>
                {params.data?._id && (
                  <OrderActions
                    {...params}
                    loadingAction={loadingAction}
                    setLoadingAction={setLoadingAction}
                    setIndexId={setIndexId}
                    indexId={indexId}
                    setFieldName={setFieldName}
                    fieldName={fieldName}
                    setIsRefundModal={(newVal) => setIsRefundModal(newVal)}
                    setOldOrderStatus={(orderStatusVal) =>
                      setOldOrderStatus(orderStatusVal)
                    }
                    setOrderTotalAmount={(totalAmount) =>
                      setOrderTotalAmount(totalAmount)
                    }
                    refundFormData={(refundFormDataVal) =>
                      refundFormData(refundFormDataVal)
                    }
                    setRefundFormData={(refundFormDataValue) =>
                      setRefundFormData(refundFormDataValue)
                    }
                    setDownloadInvoiceStatus={(invoiceVal) =>
                      setDownloadInvoiceStatus(invoiceVal)
                    }
                    setSendEmail={(emailVal) => setSendEmail(emailVal)}
                  ></OrderActions>
                )}
              </>
            ) : null}
          </div>
        ),
      },
    ];
  } else if (
    user?.isStaff &&
    user?.permission.includes(Constants.PERMISSION_NAME.BUILD_SHOP_MANAGEMENT)
  ) {
    columns = [
      {
        field: 'order_id',
        headerName: 'Order Id',
        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          closeOnApply: true,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
          debounceMs: 800,
        },
        colSpan: (params) => {
          if (params.data === undefined) {
            // have all Russia age columns width 2
            return 10;
          } else {
            // all other rows should be just normal
            return 0;
          }
        },

        cellRenderer: renderOrderId,
      },

      {
        field: 'ordered_on',
        headerName: 'Date',

        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        sortable: true,
        filter: 'agDateColumnFilter',
        // pass in additional parameters to the text filter
        filterParams: {
          closeOnApply: true,
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          filterOptions: Constants.DATE_FILTER_OPTIONS,
        },
        cellRenderer: (params) => (
          <>
            <div className="primary-Outer">
              <Moment
                format={
                  process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'
                }
                locale="de"
              >
                {params.value}
              </Moment>
              <span>
                <Moment format="HH:mm" locale="de">{params.value}</Moment>
              </span>
            </div>
          </>
        ),
      },
      {
        field: 'user_detail.email',
        headerName: 'Customer',
        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        filter: 'agTextColumnFilter',
        filterParams: {
          closeOnApply: true,
          debounceMs: 800,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
        },
        cellRenderer: ({ data }) => {
          const userName = () => (
            <>
              <div className="primary-Outer">
                {data?.user_name}
                <span>{data?.user_email}</span>
              </div>
            </>
          );
          const dealer =
            parseInt(data?.user_type) === USER_TYPE['DEALER']
              ? 'dealers'
              : undefined;

          const url =
            parseInt(data?.user_type) === USER_TYPE['CUSTOMER']
              ? 'customers'
              : dealer;

          return <>{userName()}</>;
        },
      },
      {
        field: 'shipping_city',
        headerName: 'Ship To',
        suppressSizeToFit: false,
        suppressMovable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          closeOnApply: true,
          debounceMs: 800,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
        },
        // wrapText: true,

        cellClass: 'suppress-movable-col',
        sortable: true,
        // sort: true,
        // headerSortingStyle,
        cellRenderer: (params) => (
          <div>
            <div className="primary-Outer">
              {params.data.shipping_city +
                ', ' +
                (params.data.shipping_state ? params.data.shipping_state : ' ')}
              <span>{params.data.shipping_country}</span>
            </div>
          </div>
        ),
      },

      {
        field: '_id',
        headerName: 'Actions',
        suppressSizeToFit: false,
        suppressMovable: true,
        // wrapText: true,
        //
        cellClass: 'suppress-movable-col',
        cellRenderer: (params) => (
          <div>
            {
              <Link to={`/admin/orders/details/${params.data?._id}`}>
                <Button type="button" size="sm" color="info" title="View">
                  <i className="fa fa-eye"></i>
                </Button>
              </Link>
            }
          </div>
        ),
      },
    ];
  } else if (
    user?.isStaff &&
    user?.permission.includes(Constants.PERMISSION_NAME.SHIPPING_MANAGEMENT)
  ) {
    columns = [
      {
        field: 'order_id',
        headerName: 'Order Id',

        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          closeOnApply: true,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
          debounceMs: 800,
        },
        colSpan: (params) => {
          if (params.data === undefined) {
            return 10;
          } else {
            return 0;
          }
        },

        cellRenderer: renderOrderId,
      },

      {
        field: 'ordered_on',
        headerName: 'Date',

        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        sortable: true,
        filter: 'agDateColumnFilter',
        // pass in additional parameters to the text filter
        filterParams: {
          closeOnApply: true,
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          filterOptions: Constants.DATE_FILTER_OPTIONS,
        },
        cellRenderer: (params) => (
          <>
            <div className="primary-Outer">
              <Moment
                format={
                  process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'
                }
                locale="de"
              >
                {params.value}
              </Moment>
              <span>
                <Moment format="HH:mm" locale="de">{params.value}</Moment>
              </span>
            </div>
          </>
        ),
      },
      {
        field: 'user_detail.email',
        headerName: 'Customer',
        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        filter: 'agTextColumnFilter',
        filterParams: {
          closeOnApply: true,
          debounceMs: 800,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
        },
        cellRenderer: ({ data }) => {
          const userName = () => (
            <>
              <div className="primary-Outer">
                {data?.user_name}
                <span>{data?.user_email}</span>
              </div>
            </>
          );
          const dealer =
            parseInt(data?.user_type) === USER_TYPE['DEALER']
              ? 'dealers'
              : undefined;

          const url =
            parseInt(data?.user_type) === USER_TYPE['CUSTOMER']
              ? 'customers'
              : dealer;

          return <>{userName()}</>;
        },
      },
      {
        field: 'shipping_city',
        headerName: 'Ship To',
        suppressSizeToFit: false,
        suppressMovable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          closeOnApply: true,
          debounceMs: 800,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
        },
        cellClass: 'suppress-movable-col',
        sortable: true,
        cellRenderer: (params) => (
          <div>
            <div className="primary-Outer">
              {params.data.shipping_city +
                ', ' +
                (params.data.shipping_state ? params.data.shipping_state : ' ')}
              <span>{params.data.shipping_country}</span>
            </div>
          </div>
        ),
      },

      {
        field: '_id',
        headerName: 'Actions',
        suppressSizeToFit: false,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        cellRenderer: (params) => (
          <div>
            <>
              <Link to={`/admin/orders/details/${params.data?._id}`}>
                <Button type="button" size="sm" color="info" title="View">
                  <i className="fa fa-eye"></i>
                </Button>
              </Link>
              <Link to={`/admin/orders/shipping-boxes/${params.data?.orderMergeShippingId ? params.data?.orderMergeShippingId : params.data?._id}`}>
                <Button
                  type="button"
                  size="sm"
                  color="success"
                  title="Shipping Options"
                >
                  <i className="fa fa-print" id="printLabel"></i>
                </Button>
              </Link>
            </>
          </div>
        ),
      },
    ];
  } else {
  }

  //################## END Set properties(options) of datatables ###################
  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  // ################## On Filter Changed #################
  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });
  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(async () => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          // Use startRow and endRow for sending pagination to Backend
          // params.startRow : Start Page
          // params.endRow : End Page
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();
          const sortModel = params.sortModel?.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ?? {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };
          let customData = {};
          if (user?.isAdmin == true || user?.permission.includes(Constants.PERMISSION_NAME.ORDER_MANAGEMENT)) {
            customData = await getOrdersList(customParams);
          } else if (user?.isStaff == true) {
            customData = await getOrdersListStaff(
              customParams,
              user.permission[0]
            );
          } else {
            customData = await getOrdersList(customParams);
          }

          if (customData.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            /*  if (sortingParams.onLoad) {
               gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
             } */
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };

      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  const handleCSVDownload = useCallback(async (event) => {
    let data = await getOrdersToDownload(sortingParams);
    setDownloadData(data.data);
    document.getElementById('downloadCsv').click();
  });

  const resetButtonClick = useCallback((e) => {
    e.preventDefault();
    gridRef.current.api.setFilterModel({});
  });

  const changeFilter = useCallback((status) => () => {
    setActiveClass(status);
    gridApi.setFilterModel({
      order_status: { filterType: 'text', type: status },
    });
  });

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec myorder">
                <div className="add-button-div ">
                  {user?.isAdmin ||
                    (user?.isStaff &&
                      user?.permission.includes(
                        Constants.PERMISSION_NAME.ORDER_MANAGEMENT
                      )) ? (
                    <>
                      <Button
                        onClick={resetButtonClick}
                        className={
                          ![
                            'order_pending',
                            'order_paid',
                            'order_return_initiated',
                            'order_returned',
                          ].includes(activeClass)
                            ? 'btn btn-link active'
                            : 'btn btn-link'
                        }
                      >
                        All
                      </Button>
                      {orderStatus.map((item) => (
                        <Button
                          key={item.status_label}
                          className={
                            activeClass === item.status_label
                              ? 'btn btn-link active'
                              : 'btn btn-link'
                          }
                          size="sm"
                          onClick={changeFilter(item.status_label)}
                        >
                          {item.statusText} ({item.statusCount})
                        </Button>
                      ))}
                    </>
                  ) : null}
                  <a
                    href={downloadData}
                    id="downloadCsv"
                    download
                    hidden
                    className="hide"
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </div>
                {user?.isAdmin ||
                  user?.permission?.includes(
                    Constants.PERMISSION_NAME.ORDER_MANAGEMENT
                  ) ? (
                  <div className="rightpaneltop">
                    {/*  {Object.keys(sortingParams.filter).length ? <Button onClick={resetButtonClick} className='btn btn-link btn-sm'>Reset Filters</Button> : null} */}
                    <Button
                      color="primary"
                      size="sm"
                      onClick={handleCSVDownload}
                    >
                      <i className="fa fa-download"></i> Export CSV
                    </Button>
                  </div>
                ) : null}
                <div className="rightpaneltop">
                  Orders Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      key={refreshCount}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {refundFormData.product?.length > 0 && (
        <RefundModel
          isopen={isRefundModal}
          setIsRefundModal={setIsRefundModal}
          toggleRefundModal={toggleRefundModal}
          refundFormData={refundFormData}
          orderTotalAmount={orderTotalAmount}
          isReturnInitiate={false}
        />
      )}
      <DeliveredRemarkModel
        isOpen={isDeliveryRemarkModal}
        setIsDeliveryRemarkModal={setIsDeliveryRemarkModal}
        toggleDeliveredModal={toggleDeliveredModal}
        deliveryRemark={deliveryRemark}
      />
      <PaymentTypeModel
        isPaymentModel={isPaymentModel}
        togglePaymentTypeModel={togglePaymentTypeModel}
        paymentStatusDetail={paymentStatusDetail}
      />

      <Modal isOpen={isOnHold}>
        <Form className="form-horizontal">
          <ModalHeader toggle={toggleOnHoldModal}>
            Remarks
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Label className="p-0">Message<span>*</span></Label>
                <Input
                  type="textarea"
                  name="notes"
                  id="notes"
                  value={onHoldNotes}
                  placeholder="Please enter message/notes"
                  required
                  onChange={(e) => handleChangeOnHold(e)}
                ></Input>
                {validateOnHold && <FormText className='text-danger'>Message is required</FormText>}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              color="primary"
              type="button"
              onClick={handleOnHoldSubmit}
            >
              Submit
            </Button>
            <Button
              size="sm"
              color="danger"
              data-id="closeOnHold"
              onClick={toggleOnHoldModal}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      {/*###########################  modal for refund amount  ####################################*/}
      {/*  <Modal isOpen={isRefundModal}>
        <Form className="form-horizontal">
          <ModalHeader toggle={toggleViewRefundModal}>
            Refund Amount
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Label className="p-0">
                  Refund Amount <span>*</span>
                </Label>
                <Input
                  type="number"
                  name="refunded_amount"
                  id="refunded_amount"
                  value={Number(refundFormData.refunded_amount)}
                  placeholder="Please enter refund amount"
                  min="0"
                  max={orderTotalAmount}
                  onChange={(e) => handleRefundChange(e)}
                ></Input>
                {validateMoreAmount &&
                  Number(refundFormData.refunded_amount) >
                  Number(refundFormData.grand_total) && (
                    <div className="text-danger">
                      Refund amount should less than grand total amount.
                    </div>
                  )}
                {validate &&
                  (refundFormData.refunded_amount == undefined ||
                    refundFormData.refunded_amount == '') && (
                    <div className="text-danger">
                      Please enter refund amount.
                    </div>
                  )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Label className="p-0">Refund Notes</Label>
                <Input
                  type="textarea"
                  name="refund_notes"
                  id="refund_notes"
                  placeholder="Please enter refund notes"
                  minLength={1}
                  maxLength={255}
                  onChange={(e) => handleRefundChange(e)}
                ></Input>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              color="primary"
              type="button"
              onClick={(e) => togglePreviewModel(e)}
            >
              Submit
            </Button>
            <Button
              size="sm"
              color="danger"
              data-id="closeRefundModel"
              onClick={toggleViewRefundModal}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal> */}

      {/*###########################  modal for refund preview amount  ####################################*/}
      <Modal isOpen={isPreviewModel}>
        <ModalHeader>Preview Refund Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Table bordered striped responsive size="sm">
                {refundFormData && (
                  <tbody>
                    <tr>
                      <td>Total Amount</td>
                      <td>${refundFormData.grand_total}</td>
                    </tr>
                    <tr>
                      <td>Refund Amount</td>
                      <td>${refundFormData.refunded_amount}</td>
                    </tr>
                    <tr className="data-ptag">
                      <td>Remaining Amount</td>
                      <td>
                        $
                        {(
                          refundFormData.grand_total -
                          refundFormData.refunded_amount
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Refund Note</td>
                      <td>{refundFormData.refund_notes}</td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            color="primary"
            type="button"
            onClick={(e) => onAddRefund(e)}
          >
            Submit
          </Button>
          <Button
            size="sm"
            color="danger"
            data-id="closeModel"
            onClick={togglePreviewModel}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div className="add-button-div">
        <a
          href={downloadInvoiceURL}
          id="downloadInvoice"
          hidden
          className="hide"
          target="_blank"
          download
        ></a>
      </div>
    </div>
  );
};

OrdersList.propTypes = {
  getOrdersList: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  orderList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  orderList: state.order.orderList,
  loading: state.order.loading,
  subLoading: state.order.isSubLoading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getOrdersList,
  changeStatus,
  changeOrderStatus,
  getOrdersListStaff,
  downloadInvoice,
  sendEmailToServer,
  changePaymentStatus
})(OrdersList);
