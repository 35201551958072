/* eslint-disable */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import Errors from 'views/Notifications/Errors';
import { Row, Col, Input, Label, FormGroup } from 'reactstrap';
import SetProductQuantity from './Components/SetProductQuantity';
import Select from 'react-select';
import {
    selectedVariations
} from 'actions/admin/order';
import CustomizeShaft from './Components/CustomizeShaft';

let customizationProductField = [
    [
        { labelName: 'Clubhead Make and Model', name: 'adaptor', type: 'adaptor', isOption: false, placeholder: 'Select Model', changeOptions: 'adaptor' },
        { labelName: 'Adaptor Setting', name: 'adaptor_setting', type: 'adaptor', isOption: true, placeholder: 'Select Setting', changeOptions: 'adaptor_option' }
    ],
    [
        { labelName: 'Playing Length', name: 'playing_length', type: 'playing', isOption: false, placeholder: 'Select Length', changeOptions: 'playing' },
    ],
    [
        { labelName: 'Grip: Select Brand', name: 'grip', type: 'grip', isOption: false, placeholder: 'Select Brand', changeOptions: 'grip' },
        { labelName: 'Grip: Select Model', name: 'grip_model', type: 'grip', isOption: true, placeholder: 'Select Model', changeOptions: 'grip_option' },
    ],
    [
        {
            labelName: 'Extra Wraps', name: 'extra_wrap', type: 'extra_wraps', isOption: false, placeholder: 'Select Wraps', changeOptions: 'extra_wrap', options: [
                { label: 'None', value: "1" },
                { label: '1', value: "2" },
                { label: '2', value: "3" }
            ]
        },
        {
            labelName: 'Grip Logo', name: 'grip_logo', type: 'grip_logo', isOption: false, placeholder: 'Select Grip Logo', changeOptions: 'grip_logo', options: [
                { label: 'N/A', value: 'N/A' },
                { label: 'Up', value: 'up' },
                { label: 'Down', value: 'down' },
            ]
        },
    ]
]

const Variation = ({
    variationData,
    selectedVariations,
    currentProduct,
}) => {

    const [customShaftChecked, setCustomShaftChecked] = useState(true);
    const [wpPrice, setWpPrice] = useState(0);

    useEffect(() => {
        variationData?.selectedFittingsData &&
            variationData?.selectedFittingsData.adaptor &&
            setCustomShaftChecked(false);

        if (currentProduct?.variations?.length) {

            const setVariationData = {};

            if (variationData?.isEdit) {
                const stockQuantity = currentProduct.variations.find(variationItem => variationItem.sku === variationData?.selectedProduct?.productVariationSku)?.stock_quantity
                if (stockQuantity !== undefined) {
                    variationData = {
                        ...variationData,
                        stock_quantity: stockQuantity
                    }
                }
                (variationData?.selectedFittingsData && Object.keys(variationData?.selectedFittingsData).length > 0)
                    ? variationData = { ...variationData, customization: true }
                    : variationData = { ...variationData, customization: false };
                (variationData?.selectedFittingsData && Object.keys(variationData?.selectedFittingsData).length > 0)
                    ? variationData = { ...variationData, selectedFitting: variationData.selectedFittingsData }
                    : variationData = { ...variationData, selectedFitting: {} };

                variationData = {
                    ...variationData, productVariatonDetails: {
                        product_id: variationData?.selectedProduct?.product_id,
                        product_variation_sku: variationData?.selectedProduct?.productVariationSku,
                        product_variation_id: variationData?.selectedProduct?.product_id,
                        quantity: variationData?.selectedProduct?.quantity,
                    }
                };
                selectedVariations(variationData)

            } else {
                setCustomShaftChecked(true);
                variationData = { ...variationData, selectedFittingsData: {} };
                currentProduct.variations.map((item, index) => {
                    if (item.isDefault === true) {
                        variationData = {
                            ...variationData,
                            stock_quantity: item.stock_quantity
                        }
                        if (item.stock_quantity <= 0) {
                            variationData = {
                                ...variationData,
                                productVariatonDetails: {},
                                variationSelectErrorMsg: 'Just ran out of stock. Please select a different option.'
                            }
                        } else {
                            variationData = {
                                ...variationData,
                                variationSelectErrorMsg: ''
                            }
                        }

                        item.variation.map((variation) => {
                            setVariationData[variation.attribute_id] = variation.option_id;
                        });

                        variationData = {
                            ...variationData,
                            variation: setVariationData,
                            productVariatonDetails: {
                                product_id: item.productId,
                                product_variation_sku: item.sku,
                                product_variation_id: item._id,
                                quantity: variationData.quantity,
                            }
                        }
                        selectedVariations(variationData)
                    }
                });
            }
        }
    }, [variationData?.isEdit]);

    const getSelectedProductVariations = (selectedVars) => {

        let selectedVariationDataFound = null;
        currentProduct.variations.every(variation => {
            const totalVariations = variation.variation.length;
            let quelifiedAttr = 0;
            variation.variation.forEach(variationOption => {
                if (variationOption.option_id === selectedVars[variationOption.attribute_id]) {
                    quelifiedAttr += 1;
                }
            });

            if (totalVariations === quelifiedAttr) {
                selectedVariationDataFound = variation
                return false;
            }
            return true;

        })
        return selectedVariationDataFound;
    }

    const OnChangeAttribute = (options) => {
        let selectedStockQuentity = 0;
        variationData.variation[options.attribute_id] = options.value;
        let message = false;

        if (Object.keys(variationData.variation).length === currentProduct.attributes.length) {
            let variationIsFound = false;
            const newVariation = getSelectedProductVariations(variationData.variation)
            const getCurrentQuantity = currentProduct.variations.find((item) => item.sku === newVariation.sku)
            if (getCurrentQuantity !== undefined) {
                variationData = {
                    ...variationData,
                    /* quantity: 1, */
                    stock_quantity: getCurrentQuantity.stock_quantity
                }
            }
            if (newVariation) {
                variationIsFound = true;
                selectedStockQuentity = newVariation.stock_quantity;
                variationData = {
                    ...variationData,
                    stockQuantity: newVariation.stock_quantity,
                    productVariatonDetails: {
                        product_id: newVariation.productId,
                        product_variation_sku: newVariation.sku,
                        product_variation_id: newVariation._id,
                        quantity: variationData.quantity,
                    }
                };
                message = '';
            }
            if (getCurrentQuantity?.stock_quantity < variationData?.quantity) {
                const quantityStock = getCurrentQuantity.variation.find((item) => item.option_term === options.label);
                //variationData = { ...variationData, productVariatonDetails: {} }
                message = `The selected variation, ${quantityStock.attribute_name} (${quantityStock.option_term}), has a quantity of ${newVariation.stock_quantity} available, but you have chosen a quantity of ${variationData.quantity}`;
            } else {
                //if variation does not matches then reset it
                if (!variationIsFound) {
                    variationData = { ...variationData, productVariatonDetails: {} }

                    // setProductVariationDetails({});
                    message = 'Selected combination is not available.';
                } else {
                    if (selectedStockQuentity <= 0) {
                        variationData = { ...variationData, productVariatonDetails: {} }
                        // setProductVariationDetails({});
                        message = 'Just ran out of stock. Please select a different option.';
                    } else {
                        message = '';
                    }
                }
            }
        }
        variationData = {
            ...variationData,
            variationSelectErrorMsg: message
        }
        selectedVariations(variationData)
    }

    const attributesOptions = (attributesItem, attribute_id) => {
        return attributesItem.map((item) => {
            return { label: item.option_term, value: item.option_id, attribute_id }
        })
    }

    const defaultVariation = (attributesItem, attribute_id) => {
        const variationName = attributesItem.find((t) => t.option_id === attribute_id)?.option_term;
        if (variationName !== undefined) {
            return [{ label: variationName, value: attribute_id }]
        }
        return [];
    }

    const handelCustomShaftChange = useCallback((e) => {
        if (e.target.checked) {
            variationData = {
                ...variationData,
                quantity: 1
            }
        } else {
            variationData = {
                ...variationData,
                selectedFitting: {},
                selectedFittingsData: {}
            }
        }
        variationData = {
            ...variationData,
            customization: e.target.checked
        }

        setCustomShaftChecked(!customShaftChecked);
        selectedVariations(variationData)
    });

    useEffect(() => {
        if (currentProduct && currentProduct.variations.length) {
            currentProduct?.attributes?.find(item => item?.attribute_name === 'Flex' && item?.attributes?.sort((a, b) => a.option_term.localeCompare(b.option_term)))
            currentProduct?.attributes?.find(item => ['Iron', 'Iron #'].includes(item?.attribute_name) && item?.attributes?.sort((a, b) => a.option_term.localeCompare(b.option_term)))
        }
    }, [currentProduct])

    const onKeyDown = (event) => {
        if ([189, 187].includes(event.keyCode)) {
            event.preventDefault();
        }
    }

    const wpProductPrice = useCallback((event) => {
        variationData = {
            ...variationData,
            wp_product_price: event.target.value
        }
        setWpPrice(event.target.value);
        selectedVariations(variationData);
    })

    useEffect(() => {
        const priceWp = variationData?.wp_price.find((v) => v.sku === variationData?.productVariatonDetails?.product_variation_sku)?.price ?? 0;
        variationData = {
            ...variationData,
            wp_product_price: Number(priceWp)
        }
        setWpPrice(Number(priceWp));
        selectedVariations(variationData);
    }, [variationData?.wp_price])

    return currentProduct.attributes && currentProduct.attributes.length > 0 ? (
        <>
            <SetProductQuantity
                customShaftChecked={customShaftChecked}
            />
            {variationData.order_category !== "normal_order" && <Col sm="12">
                <FormGroup>
                    <Label for={"price"} className="variation-heading">
                        Price
                    </Label>
                    <Input type="number" min={0} minLength={0} step={0.01} value={wpPrice} name="wp_product_price" onKeyDown={onKeyDown} onChange={wpProductPrice} />
                </FormGroup>
            </Col>}
            {variationData.variation && currentProduct.attributes.map((item, index) => (
                <Col sm="12" key={item.attribute_id}>
                    <FormGroup>
                        <Label for={item.attribute_name} className="variation-heading">
                            {item.attribute_name && item.attribute_name == 'Colors'
                                ? 'Color'
                                : item.attribute_name}
                        </Label>
                        <Select
                            value={defaultVariation(item.attributes, variationData?.variation[item.attribute_id])}
                            isClearable={false}
                            options={attributesOptions(item.attributes, item.attribute_id)}
                            onChange={OnChangeAttribute}

                            keepSelectedInList={false}

                        />
                    </FormGroup>
                </Col>)
            )}
            {(currentProduct.categoryId.customization && !["warranty_order", "promo_order"].includes(variationData.order_category)) && (
                <Col sm="12">
                    <FormGroup
                        check
                        inline
                    >
                        <Input type="checkbox" name="customize_shaft" id="customize_shaft" checked={!customShaftChecked} onClick={handelCustomShaftChange} />
                        <Label check className="padding-left">
                            Customize Shaft
                        </Label>
                    </FormGroup>
                </Col>
            )}

            {customShaftChecked && customShaftChecked == true ? ("")
                : (
                    <div className="selectcolor">
                        <div className="selectproductcolor">
                            <Row className="mt-3">
                                <CustomizeShaft customizationProductField={customizationProductField} currentProduct={currentProduct} />
                            </Row>
                        </div>
                    </div>
                )}
        </>
    ) : (
        <div>Out of stock OR not allowed to purchase</div>
    );
};

const mapStateToProps = (state) => ({
    variationData: state.order.variationData,
});

export default connect(mapStateToProps, {
    selectedVariations
})(Variation);
